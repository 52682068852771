var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-view"},[_c('page-title'),_c('pageable',{attrs:{"url":_vm.url,"query":_vm.formData,"downloadUrl":"/user/excel"},scopedSlots:_vm._u([{key:"condition",fn:function(){return [_c('el-form',{attrs:{"model":_vm.formData,"inline":true}},[_c('el-form-item',{attrs:{"label":"用户ID"}},[_c('el-input-number',{attrs:{"min":1,"step":1},model:{value:(_vm.formData.id),callback:function ($$v) {_vm.$set(_vm.formData, "id", $$v)},expression:"formData.id"}})],1),_c('el-form-item',{attrs:{"label":"手机号"}},[_c('el-input',{attrs:{"placeholder":"请输入用户手机号","clearable":""},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})],1),_c('el-form-item',{attrs:{"label":"邀请者"}},[_c('user-search',{model:{value:(_vm.formData.invitor),callback:function ($$v) {_vm.$set(_vm.formData, "invitor", $$v)},expression:"formData.invitor"}})],1),_c('el-form-item',{attrs:{"label":"注册"}},[_c('date-range-picker',{attrs:{"name":"注册"},model:{value:(_vm.formData.createdAt),callback:function ($$v) {_vm.$set(_vm.formData, "createdAt", $$v)},expression:"formData.createdAt"}})],1)],1)]},proxy:true},{key:"table",fn:function(){return [_c('el-table-column',{attrs:{"prop":"id","label":"ID","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{on:{"click":function($event){return _vm.clickId(row)}}},[_vm._v(_vm._s(row.id))])]}}])}),_c('el-table-column',{attrs:{"prop":"username","label":"用户名"}}),_c('el-table-column',{attrs:{"prop":"nickname","label":"昵称"}}),_c('el-table-column',{attrs:{"label":"头像"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"30px","height":"30px"},attrs:{"src":row.avatar,"fit":"cover","preview-src-list":[row.avatar]}})]}}])}),_c('el-table-column',{attrs:{"label":"openid","prop":"openid","min-width":"100"}}),_c('el-table-column',{attrs:{"label":"游戏ID","prop":"gameId","min-width":"100"}}),_c('el-table-column',{attrs:{"label":"账户状态","prop":"status","show-overflow-tooltip":"","min-width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status == 'NORMAL')?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("正常")]):_vm._e(),(row.status == 'LOCK')?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("锁定中")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"注册时间","prop":"createdAt","min-width":"150"}}),_c('el-table-column',{attrs:{"label":"操作","align":"center","fixed":"right","min-width":"280"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.getInviteInfo(row)}}},[_vm._v("邀请列表")]),_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.editRow(row)}}},[_vm._v("编辑")])]}}])})]},proxy:true}])}),_c('el-dialog',{attrs:{"visible":_vm.showDialog,"title":"邀请列表","width":"800px","top":"10vh"},on:{"update:visible":function($event){_vm.showDialog=$event}}},[_c('div',{staticClass:"filter-container",staticStyle:{"text-align":"right"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$exportTable(_vm.$refs.inviteRef,'邀请列表')}}},[_vm._v("导出列表")])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.dialogLoading),expression:"dialogLoading"}],ref:"inviteRef",attrs:{"data":_vm.list,"height":"60vh"}},[_c('el-table-column',{attrs:{"prop":"id","label":"ID","width":"80"}}),_c('el-table-column',{attrs:{"prop":"nickname","label":"昵称"}}),_c('el-table-column',{attrs:{"prop":"phone","label":"手机"}}),_c('el-table-column',{attrs:{"prop":"createdAt","label":"注册时间"}}),_c('el-table-column',{attrs:{"prop":"authStatus","label":"实名审核状态","formatter":_vm.statusFormatter}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }