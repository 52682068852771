<template>
    <div class="list-view">
        <page-title>

        </page-title>
        

        <pageable :url="url" :query="formData"  downloadUrl="/user/excel">
            <template v-slot:condition>
                <el-form :model="formData" :inline="true" >

                    <el-form-item label="用户ID">
                        <el-input-number v-model="formData.id" :min="1" :step="1" ></el-input-number>
                    </el-form-item>
                    <el-form-item label="手机号">
                        <el-input v-model="formData.phone"  placeholder="请输入用户手机号" clearable="" ></el-input>
                    </el-form-item>
                    <el-form-item label="邀请者">
                        <user-search v-model="formData.invitor"></user-search>
                    </el-form-item>
                    <el-form-item label="注册">
                        <date-range-picker v-model="formData.createdAt"  name="注册"></date-range-picker>
                    </el-form-item>
                </el-form>

            </template>

            <template v-slot:table>
                <!-- <el-table-column align="center" type="selection" width="50"> </el-table-column> -->
                <el-table-column prop="id" label="ID" width="100">
                    <template v-slot="{ row }">
                        <span @click="clickId(row)">{{ row.id }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="username" label="用户名"> </el-table-column>
                <el-table-column prop="nickname" label="昵称"> </el-table-column>
                <el-table-column label="头像">
                    <template v-slot="{ row }">
                        <el-image
                            style="width: 30px; height: 30px"
                            :src="row.avatar"
                            fit="cover"
                            :preview-src-list="[row.avatar]"
                        ></el-image>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="手机" prop="phone" min-width="100"></el-table-column> -->
                <el-table-column label="openid" prop="openid" min-width="100"></el-table-column>
                <el-table-column label="游戏ID" prop="gameId" min-width="100"></el-table-column>
                <el-table-column label="账户状态" prop="status" show-overflow-tooltip="" min-width="80">
                    <template v-slot="{ row }">
                        <el-tag type="success" v-if="row.status == 'NORMAL'">正常</el-tag>
                        <el-tag type="danger" v-if="row.status == 'LOCK'">锁定中</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="注册时间" prop="createdAt" min-width="150"></el-table-column>
                
                <el-table-column label="操作" align="center" fixed="right" min-width="280">
                    <template v-slot="{ row }">
                        <el-button @click="getInviteInfo(row)" type="text" size="mini">邀请列表</el-button>
                        <el-button @click="editRow(row)" type="text" size="mini">编辑</el-button>
                    </template>
                </el-table-column>

            </template>

        </pageable>
       

        <el-dialog :visible.sync="showDialog" title="邀请列表" width="800px" top="10vh">
            <div class="filter-container" style="text-align:right;">
                <el-button type="primary" @click="$exportTable($refs.inviteRef,'邀请列表')">导出列表</el-button>
            </div>
            <el-table ref="inviteRef" :data="list" v-loading="dialogLoading" height="60vh">
                <el-table-column prop="id" label="ID" width="80"></el-table-column>
                <el-table-column prop="nickname" label="昵称"></el-table-column>
                <el-table-column prop="phone" label="手机"></el-table-column>
                <el-table-column prop="createdAt" label="注册时间"></el-table-column>
                <el-table-column prop="authStatus" label="实名审核状态" :formatter="statusFormatter"></el-table-column>
            </el-table>
        </el-dialog>


    </div>
</template>
<script>
import { mapState } from 'vuex';


export default {
    data() {
        return {
            url: '/user/all',
            formData: {
                admin: false,
                createdAt: '',
                phone: '',
                invitor:'',
            },
            showDialog: false,
            dialogLoading: false,
            list: [],
            statusOptions: [
                { label: '未认证', value: 'NOT_AUTH' },
                { label: '认证中', value: 'PENDING' },
                { label: '已认证', value: 'SUCCESS' },
                { label: '失败', value: 'FAIL' }
            ],
            
        };
    },
    computed: {
        ...mapState([]),
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },
    methods: {
        statusFormatter(row, column, cellValue, index) {
            let selectedOption = this.statusOptions.find(i => i.value === cellValue);
            if (selectedOption) {
                return selectedOption.label;
            }
            return '';
        },
        addRow() {
            this.$router.push({
                path: '/userEdit',
                query: {
                    ...this.$route.query
                }
            });
        },
        editRow(row) {
            this.$router.push({
                path: '/userEdit',
                query: {
                    id: row.id
                }
            });
        },
        getInviteInfo(row) {
            this.list = [];
            this.showDialog = true;
            this.dialogLoading = true;
            this.$http
                .post('/user/all', { size: 10000, sort: 'id,desc', query: { invitor: row.id } }, { body: 'json' })
                .then(res => {
                    this.list = res.content;
                    this.dialogLoading = false;
                });
        },
      
    },

};
</script>
<style lang="less" scoped></style>
